import { useState } from 'react';
import QuestionnaireType from '_types/api/questionnaires/QuestionnaireType';

function useQuestionnaireTypesPanel() {
    const [selectedType, setSelectedType] = useState<QuestionnaireType | null>(null);

    return {
        selectedType,
        setSelectedType,
    };
}

export default useQuestionnaireTypesPanel;
