import { ReactNode } from 'react';
import { Row } from 'react-bootstrap';
import styles from './questionnaireLSection.module.scss';

type QuestionnaireLSectionProps = {
    letter: string;
    children: ReactNode;
};

// eslint-disable-next-line no-empty-pattern
const QuestionnaireLSection = ({ letter, children }: QuestionnaireLSectionProps) => {
    const label = letter.toLocaleUpperCase();
    return (
        <div>
            <h2 className={styles.letter}>{label}</h2>
            <Row>{children}</Row>
        </div>
    );
};

export default QuestionnaireLSection;
