import { Col, Row } from 'react-bootstrap';
import QuestionnaireListItem, { QuestionnaireListItemProps } from 'components/_questionnaires/ListItem';
import { sortBy } from 'ramda';
import { QuestionnaireLibraryModule } from '_types/api/questionnaires/QuestionnaireLibraryModule';
import QuestionnaireType from '_types/api/questionnaires/QuestionnaireType';

export type QuestionnaireListProps = {
    questionnaires: (QuestionnaireListItemProps | QuestionnaireLibraryModule)[];
    colSm?: number;
    isPDFPage?: boolean;
};

const QuestionnaireList = ({ questionnaires, colSm = 4, isPDFPage = false }: QuestionnaireListProps) => {
    return (
        <>
            <Row>
                {questionnaires &&
                    sortBy((item) => item.name, questionnaires).map((q) => {
                        return (
                            <Col sm={colSm} className="my-3" key={q.id}>
                                <QuestionnaireListItem
                                    id={q.id}
                                    name={q.name}
                                    state={(q as QuestionnaireListItemProps)?.state}
                                    type={q.type.toString().toLowerCase() as QuestionnaireType}
                                    code={q.code}
                                    numQuestions={(q as QuestionnaireListItemProps)?.numQuestions}
                                    isCustom={(q as QuestionnaireListItemProps)?.isCustom}
                                    isPDFPage={isPDFPage}
                                />
                            </Col>
                        );
                    })}
            </Row>
        </>
    );
};

export default QuestionnaireList;
