import { useEffect, useState } from 'react';
import QuestionnaireLSection from 'components/_questionnaires/Section';
import QuestionnaireList from '../List';
import { groupBy, sortBy, pipe, toPairs } from 'ramda';
import { QuestionnaireListItemProps } from '../ListItem';
import { QuestionnaireLibraryModule } from '_types/api/questionnaires/QuestionnaireLibraryModule';
export type AlphabeticalQuestionnaireListProps = {
    questionnaires: QuestionnaireListItemProps[] | QuestionnaireLibraryModule[];
    colSm: number;
    isPDFPage?: boolean;
};
const generateGroups = pipe(
    groupBy((item: QuestionnaireListItemProps | QuestionnaireLibraryModule) => item.name.trim()[0].toUpperCase()),
    toPairs,
    sortBy((item: [string, QuestionnaireListItemProps[] | QuestionnaireLibraryModule[]]) => item[0]),
);

const AlphabeticalQuestionnaireList = ({
    questionnaires,
    colSm,
    isPDFPage = false,
}: AlphabeticalQuestionnaireListProps) => {
    const [groupedQuestionnaires, setGroupedQuestionnaires] =
        useState<[string, QuestionnaireListItemProps[] | QuestionnaireLibraryModule[]][]>();

    useEffect(() => {
        setGroupedQuestionnaires(generateGroups(questionnaires));
    }, [questionnaires]);

    return (
        <>
            {groupedQuestionnaires &&
                groupedQuestionnaires.map((item) => (
                    <QuestionnaireLSection key={item[0]} letter={item[0]}>
                        <QuestionnaireList questionnaires={item[1]} colSm={colSm} isPDFPage={isPDFPage} />
                    </QuestionnaireLSection>
                ))}
        </>
    );
};

export default AlphabeticalQuestionnaireList;
