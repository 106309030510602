import styles from './index.module.scss';
import QuestionnaireType from '_types/api/questionnaires/QuestionnaireType';
import useQuestionnaireTypes from 'hooks/useQuestionnaireTypes';
import QuestionnaireKind from '_types/api/questionnaires/QuestionnaireKind';
import ExportCsvQuestionnairesModules from 'components/PublishedQuestionnairesPage/ExportCsvQuestionnaireModulesButton';

export type QuestionnaireTypesPanelProps = {
    isForQuestionnaireModules?: boolean;
    onSelectedChange: (type: QuestionnaireType | null) => void;
    selected: QuestionnaireType | null;
};

const QuestionnaireTypesPanel = ({
    onSelectedChange,
    selected,
    isForQuestionnaireModules = false,
}: QuestionnaireTypesPanelProps) => {
    const { questionnaireTypesByKind } = useQuestionnaireTypes(isForQuestionnaireModules);

    const categories: { label: string; type: QuestionnaireType | null }[] = [
        { label: 'All', type: null },
        ...questionnaireTypesByKind[QuestionnaireKind.OFFICIAL].map((t) => ({ label: t.labelPlural, type: t.type })),
    ];

    return (
        <div className={styles.wrapper}>
            <h4>Categories</h4>
            <div className={styles.listWrapper}>
                <ul>
                    {categories.map((item) => (
                        <li key={item.type}>
                            <button
                                className={[
                                    styles.icon,
                                    item.type ? styles[item.type] : '',
                                    selected === item.type ? styles.active : '',
                                ].join(' ')}
                                onClick={() => onSelectedChange(item.type)}
                            >
                                {item.label}
                                {isForQuestionnaireModules && <ExportCsvQuestionnairesModules type={item.type} />}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default QuestionnaireTypesPanel;
