import styles from './questionnaireIcon.module.scss';
import QuestionnaireState from '_types/api/questionnaires/QuestionnaireState';
import QuestionnaireType from '_types/api/questionnaires/QuestionnaireType';

export type QuestionnaireIconProps = {
    state: QuestionnaireState | null;
    type: QuestionnaireType;
    isCustom: boolean;
    code: string | null;
    number?: number;
};
const QuestionnaireIcon = ({ state, type, isCustom, code, number }: QuestionnaireIconProps) => {
    const getBgClass = (state: QuestionnaireState | null, type: QuestionnaireType) => {
        if (type === null || isCustom) {
            if (state === QuestionnaireState.WAITING_FOR_APPROVAL || state === QuestionnaireState.DRAFT) {
                return styles.draft;
            }
            return styles.previous;
        }

        return styles[type];
    };

    const getFontClass = (code: string | null) => {
        if (code && code.length > 4) {
            return styles.smallCode;
        }
        return styles.code;
    };

    return (
        <div
            className={`${getBgClass(state, type)} ${
                number !== undefined ? styles.backgroundBig : styles.background
            } d-flex justify-content-center align-items-center`}
        >
            <span className={getFontClass(code)}>{code ? code : ''}</span>
            {number !== undefined && <span className={styles.number}>{number}</span>}
        </div>
    );
};

export default QuestionnaireIcon;
