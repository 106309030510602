/* eslint-disable @next/next/link-passhref */
import styles from 'components/_questionnaires/ListItem/questionnaireListItem.module.scss';
import stylesCreate from './questionnaireListItemCreate.module.scss';

export type QuestionnaireListItemCreateProps = {
    onClick: () => void;
    text: string;
};

const QuestionnaireListItemCreate = ({ onClick, text }: QuestionnaireListItemCreateProps) => {
    return (
        <button className={`${stylesCreate.button} d-flex`} onClick={onClick}>
            <div
                className={` ${stylesCreate.create} ${styles.background} d-flex justify-content-center align-items-center`}
            />
            <div className={stylesCreate.text}>
                <span>+</span>
                {text}
            </div>
        </button>
    );
};

export default QuestionnaireListItemCreate;
