import FormSelect, { FormSelectItem, FormSelectPropsWithoutItems } from '..';
import Phase from '_types/Phase';

export const PhaseTypes: FormSelectItem[] = [
    { label: 'Phase III completed', value: Phase.PhaseIII },
    { label: 'In phase IV', value: Phase.PhaseIV },
    { label: 'Phase IV completed', value: Phase.PhaseV },
];

function FormPhaseSelect<TValue>(props: FormSelectPropsWithoutItems<TValue>) {
    return <FormSelect {...props} items={PhaseTypes} isHidingEmptyOptions={true} />;
}

export default FormPhaseSelect;
