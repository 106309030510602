import useApi from 'hooks/useApi';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { questionnairesBaseQueryKey } from 'consts/sharedQueryKeys';
import Questionnaire from '_types/api/questionnaires/Questionnaire';
import questionnairesPath, {
    publishQuestionnairePath,
    rejectQuestionnairePath,
} from 'consts/paths/api/questionnaires/questionnairesPath';
import useInvalidateQuestionnaires from 'hooks/useInvalidateQuestionnaires';
import Router from 'next/router';
import ItemType from '_types/api/items/ItemType';
import { useAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import useSelectedLanguage from './useSelectedLanguage';
import { QuestionnaireBulkItem } from '_types/api/items/QuestionnaireBulkItem';
import tryGetApiError from 'functions/api/tryGetApiError';
import useAlerts from 'hooks/useAlerts';

export const questionnaireIdAtom = atomWithReset<number | null>(null);

const useCurrentQuestionnaire = () => {
    const api = useApi();
    const { addSuccessAlert, addWarningAlert } = useAlerts();
    const invalidateQuestionnaires = useInvalidateQuestionnaires();
    const [questionnaireId, setQuestionnaireId] = useAtom(questionnaireIdAtom);
    const resetQuestionnaireId = useResetAtom(questionnaireIdAtom);
    const { selectedLanguage, resetSelectedLanguage } = useSelectedLanguage();

    const {
        data: questionnaire,
        isLoading: isQuestionnaireLoading,
        isError: isQuestionnaireError,
        error: questionnaireError,
    } = useQuery<Questionnaire, AxiosError>(
        [questionnairesBaseQueryKey, questionnaireId, selectedLanguage.code],
        async () => {
            const result = await api.get<Questionnaire>(
                `${questionnairesPath}/${questionnaireId}/details/${selectedLanguage.code}`,
            );
            return result?.data;
        },
        { enabled: !!questionnaireId },
    );

    const deleteQuestionnaire = async () => {
        const result = await api.delete<Questionnaire>(`${questionnairesPath}/${questionnaireId}`);
        await invalidateQuestionnaires();
        await Router.push('/');
        return result?.data;
    };

    const publishQuestionnaire = async () => {
        const result = await api.put<Questionnaire>(
            `${questionnairesPath}/${questionnaireId}${publishQuestionnairePath}?url=${window.location.href}`,
        );
        await invalidateQuestionnaires();
        return result?.data;
    };

    const deleteLanguageFromQuestionnaire = async () => {
        await api.delete<Questionnaire>(`${questionnairesPath}/${questionnaireId}/languages/${selectedLanguage.code}`);
        await invalidateQuestionnaires();
        resetSelectedLanguage();
    };

    const rejectQuestionnaire = async () => {
        await api.put<Questionnaire>(
            `${questionnairesPath}/${questionnaireId}${rejectQuestionnairePath}?url=${window.location.href}`,
        );
        await invalidateQuestionnaires();
    };

    const addToVerifiedLanguages = async (code: string) => {
        try {
            await api.post(`${questionnairesPath}/${questionnaireId}/languages`, { code: code });
            await invalidateQuestionnaires();
            addSuccessAlert('Questionnaire updated!');
        } catch (error: unknown) {
            const apiError = tryGetApiError(error);
            if (apiError && apiError.error_description) {
                addWarningAlert(apiError.error_description);
            } else {
                // Server error probably
                addWarningAlert('Something went wrong, please try again.');
            }
        }
    };

    const addBulkTranslations = async (data: QuestionnaireBulkItem[]) => {
        await api.post(`${questionnairesPath}/${questionnaireId}/translations/bulk-update`, data);
        await invalidateQuestionnaires();
    };

    return {
        questionnaire,
        setQuestionnaireId,
        isQuestionnaireLoading,
        isQuestionnaireError,
        questionnaireError,
        deleteQuestionnaire,
        publishQuestionnaire,
        numQuestions: questionnaire?.questionnaireItems.filter((qi) => qi.item.type === ItemType.Question).length,
        resetCurrentQuestionnaire: resetQuestionnaireId,
        deleteLanguageFromQuestionnaire,
        addToVerifiedLanguages,
        addBulkTranslations,
        rejectQuestionnaire,
    };
};

export default useCurrentQuestionnaire;
