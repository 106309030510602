import useCurrentQuestionnaire from 'components/QuestionnaireDetailsPage/hooks/useCurrentQuestionnaire';
import TooltipWrapper from 'elements/TooltipWrapper/TooltipWrapper';
import FileSaver from 'file-saver';
import useApi from 'hooks/useApi';
import styles from './exportCsvButton.module.scss';
import { questionnaireLibraryPath } from 'consts/paths/api/requests/requestsPath';
import QuestionnaireType from '_types/api/questionnaires/QuestionnaireType';
import React from 'react';

export type ExportCsvQuestionnairesModules = {
    type: QuestionnaireType | null;
};
const categoryIds = {
    core: 1,
    module: 2,
    standalone: 3,
};

const getCategoryId = (category: questionnaireModulesType) => {
    if (!category) {
        return null;
    }
    return categoryIds[category];
};

type questionnaireModulesType = 'standalone' | 'core' | 'module';

const ExportCsvQuestionnairesModules = ({ type }: ExportCsvQuestionnairesModules) => {
    const api = useApi();
    const { questionnaire } = useCurrentQuestionnaire();

    const downloadCsv = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        const catId = getCategoryId(type as questionnaireModulesType);
        const result = await api.get(`${questionnaireLibraryPath}/questionnaire/report?categoryId=${catId}`);
        const data = result.data;

        const blob = new Blob([data], {
            type: 'text/csv;charset=utf-8',
        });
        FileSaver.saveAs(blob, 'reports');
    };

    return (
        <button type="button" className={styles.download} onClick={downloadCsv} tabIndex={0}>
            <TooltipWrapper placement="top" text="Download as csv" tooltipId={`download${questionnaire?.id}`}>
                <i className="bi-download m-0 " />
            </TooltipWrapper>
        </button>
    );
};

export default ExportCsvQuestionnairesModules;
