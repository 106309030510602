import { useAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';

export type SelectLanguageType = {
    code: string;
    name: string;
};

export const languageCodeAtom = atomWithReset<SelectLanguageType>({ code: 'en', name: 'English' });

const useSelectedLanguage = () => {
    const [selectedLanguage, setSelectedLanguage] = useAtom(languageCodeAtom);
    const resetSelectedLanguage = useResetAtom(languageCodeAtom);

    return { selectedLanguage, setSelectedLanguage, resetSelectedLanguage };
};

export default useSelectedLanguage;
