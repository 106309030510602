import QuestionnaireType from '_types/api/questionnaires/QuestionnaireType';
import QuestionnaireState from '_types/api/questionnaires/QuestionnaireState';
import Link from 'next/link';
import styles from './questionnaireListItem.module.scss';
import QuestionnaireIcon from '../QuestionnaireIcon';

export type QuestionnaireListItemProps = {
    id: number;
    name: string;
    type: QuestionnaireType;
    state: QuestionnaireState | null;
    numQuestions: number;
    code: string | null;
    isCustom: boolean;
    isPDFPage?: boolean;
};

const QuestionnaireListItem = ({
    name,
    type,
    state,
    numQuestions,
    code,
    id,
    isCustom,
    isPDFPage,
}: QuestionnaireListItemProps) => {
    return (
        <Link href={isPDFPage ? `pdf-questionnaire?id=${id}` : `questionnaires?id=${id}`}>
            <a className={`${styles.link} d-flex`}>
                <QuestionnaireIcon state={state} type={type} code={code} isCustom={isCustom} />
                <div>
                    <h4 className={styles.name}>{name}</h4>
                    <div className={styles.type}>{type}</div>
                    {state === QuestionnaireState.WAITING_FOR_APPROVAL ? (
                        <span className={styles.waitingApproval}>Waiting for approval</span>
                    ) : (
                        numQuestions && (
                            <span className={styles.questionsNum}>{` (${numQuestions} ${
                                numQuestions === 1 ? 'question' : 'questions'
                            } )`}</span>
                        )
                    )}
                </div>
            </a>
        </Link>
    );
};

export default QuestionnaireListItem;
